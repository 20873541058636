.OCVAlertsDetailColOne {
    padding-top: 50px;
    padding-bottom: 30px;
}
.OCVAlertsDetailButton {
    margin-bottom: 10px;
}
.OCVAlertsDetailPaper {
    padding: 30px;
    margin-bottom: 15px;
    min-height: 350px;
}
.OCVAlertsDetailSpanOne {
    display: block;
    margin-bottom: 20px;
}
.OCVAlertsDetailSpanTwo {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 35px 40px 45px 0px;
    padding-top: 10px;
}
.OCVAlertsDetailColTwo {
    padding-bottom: 30px;
}