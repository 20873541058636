.ViewOurAppBadgeStyle {
    margin: auto;
    max-width: 180px;
    display: inline-block;
    border-radius: 5px;
    height: auto;
    max-height: 59px;
}
.ViewOurAppColOne {
    padding-top: 50px;
}
.ViewOurAppPaperOne {
    padding: 20px;
    margin-bottom: 15px;
}
.ViewOurAppImgOne {
    border-radius: 8px;
    display: block;
    margin: auto;
}
.ViewOurAppH1 {
    text-align: center;
}
.ViewOurAppColTwo {
    text-align: center;
}
.ViewOurAppAnchor {
    max-width: 180px;
    display: inline-block;
}
.ViewOurAppImgTwo {
    margin: auto;
    display: block;
}
.ViewOurAppPaperTwo {
    margin: 10px 0;
    padding: 10px;
    min-height: 150px;
}
.ViewOurAppH5 {
    display: inline;
    padding: 5px;
}
.ViewOurAppPaperThree {
    margin: 10px 0;
    text-align: left;
    padding: 10px;
    min-height: 150px;
}