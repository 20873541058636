.OCVCalendarRow {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.OCVCalendarRowMiddle {
    align-items: center;
}
.OCVCalendarCol {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}
.OCVCalendarColStart {
    justify-content: flex-start;
    text-align: left;
}
.OCVCalendarColCenter {
    justify-content: center;
    text-align: center;
}
.OCVCalendarColEnd {
    justify-content: flex-end;
    text-align: right;
}
.OCVCalendar {
    display: block;
    position: relative;
    width: 100%;
    background: #FFF;
    border: 1px solid #eee;
}
.OCVCalendarHeader {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid #eee;
}
.OCVCalendarDays {
    text-transform: uppercase;
    font-weight: 400;
    color: #ccc;
    font-size: 70%;
    padding: .75em 0;
    border-bottom: 1px solid #eee;
}
.OCVCalendarSelected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);
    border-image-slice: 1;
}
.OCVCalendarNumber {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .75em;
    right: .75em;
    font-weight: 700;
}
.OCVCalendarDisabled {
    color: #ccc;
    pointer-events: none;
}
.OCVCalendarNumEvents {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    bottom: .75em;
    left: .75em;
    font-weight: 700;
    color: #FFF;
    padding: 5px;
    background-color: #1a8fff;
    border-radius: 8px;
    width: 50%;
}
.OCVCalendarEvent {
    padding: 10px;
    margin: 15px;
}
.OCVCalendarCloseButton {
    position: absolute !important;
    right: 1em !important;
    top: 1em !important;
    color: grey;
    padding: 0 !important;
}