.OCVPhotoGalleryDiv {
    background-color: #000;
    height: 400px;
}
.OCVPhotoGalleryCol {
    padding-bottom: 30px;
}
.OCVPhotoGalleryPaper {
    padding: 20px;
}
.OCVPhotoGalleryImage {
    display: block;
    overflow: hidden;
    max-width: 100%;
    margin: auto;
}