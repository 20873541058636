.OCVAlertsContainerColOne {
    padding: 20px;
    margin: 20px 0px;
}
.OCVAlertsContainerPaperOne {
    padding: 20px;
    margin-bottom: 15px;
}
.OCVAlertsContainerExternalDiv {
    display: table;
    width: 100%;
}
.OCVAlertsContainerInternalDiv {
    display: table-cell;
    width: 72%;
    vertical-align: top;
    padding-left: 20px;
}
.OCVAlertsContainerSpanOne {
    display: block;
    margin-bottom: 20px;
}
.OCVAlertsContainerNestedDiv {
    padding-bottom: 0;
}
.OCVAlertsContainerLink {
    color: #003227;
    margin: 10px 0;
}
.OCVAlertsContainerSpanTwo {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 35px 40px 35px 0px;
    padding-top: 10px;
}
.OCVAlertsContainerColTwo {
    padding: 20px;
    margin: 20px 0px;
}
.OCVAlertsContainerPaperTwo {
    padding: 20px;
    text-align: center;
}
.OCVAlertsContainerColThree {
    padding: 20px;
    margin: 20px 0px;
}
.OCVAlertsContainerPaperThree {
    padding: 20px;
}