.OCVBlogListPaperOne {
    padding: 20px;
    margin-bottom: 15px;
}
.OCVBlogListExternalDiv {
    display: table;
    width: 100%;
}
.OCVBlogListInternalDivOne {
    display: table-cell;
    width: 28%;
    vertical-align: top;
    padding-bottom: 0;
}
.OCVBlogListNestedDivOne {
    display: block;
    /*border: '4px solid #01291c',*/
    position: relative;
    overflow: hidden;
    padding-top: 64.18%;
}
.OCVBlogListNestedImg {
    width: 100%;
    position: absolute;
    top: 0; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    margin: auto;
}
.OCVBlogListInternalDivTwo {
    display: table-cell;
    width: 72%;
    vertical-align: top;
    padding-left: 20px;
}
.OCVBlogListSpan {
    display: block;
    margin-bottom: 20px;
}
.OCVBlogListNestedDivTwo {
    padding-bottom: 0;
}
.OCVBlogListLinkOne {
    color: #003227;
}
.OCVBlogListLinkTwo {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 40px 40px 40px 0px;
    padding-left: 20px;
}
.OCVBlogListCol {
    padding: 20px;
    margin: 20px 0px;
}
.OCVBlogListPaperTwo {
    padding: 20px;
}