.OCVPageExternalDiv {
    background-color: #000;
    height: 400px;
}
.OCVPageColOne {
    padding-bottom: 30px;
}
.OCVPageImage {
    display: block;
    overflow: hidden;
    max-width: 100%;
    margin: auto;
}
.OCVPageColTwo {
    padding-top: 50px;
}
.OCVPagePaper {
    padding: 20px;
    margin-bottom: 15px;
}
.OCVPageDiv {
    padding-bottom: 30px;
}